import React from 'react';

const Music = () => {

  return (
    <div>
        Music
    </div>
  );
}

export default Music;